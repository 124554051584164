<template>
  <div id="app">
    <transition name="fade">
      <router-view></router-view>
    </transition>
    <!-- <rightsidenav :showShare="false" /> -->
  </div>
</template>

<script>
// import rightsidenav from './components/rightsidenav'
export default {
  name: 'app',
  // components: {
  //   rightsidenav
  // },
  mounted () {
    // document.title = '萌芽相册Snaphoto'
  }
}
</script>

<style lang='less'>
@import './style/common.less';
#app{
  font-size: 0;
}
.generate-delay(10);

.generate-delay(@n, @i: 1) when (@i =< @n) {
  .ani-delay-@{i} {
    animation-delay: (@i * 0.5s);
  }
  .generate-delay(@n, (@i + 1));
}
</style>
