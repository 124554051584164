import action from '@/store/action'
import store from '@/store/store'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const indexPage = r => require.ensure([], () => r(require('../App.vue')), 'indePage')
const photoAlbum = r => require.ensure([], () => r(require('../page/photoAlbum/photoAlbum.vue')), 'photoAlbum')
const verifyCom = r => require.ensure([], () => r(require('../page/verify/index.vue')), 'verify')
// requiresAuth 必须登录，否则跳转登录页面
// canNotLogin 进入后免登录

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      redirect: '/verify',
      component: indexPage,
      children: [
        {
          path: 'verify',
          name: 'verify',
          component: verifyCom
        },
        { // 相册
          path: 'photoAlbum/:id',
          name: 'photoAlbum',
          meta: {
            canNotLogin: true
          },
          component: photoAlbum
        },
        { path: '/snaphoto/activityPage', name: 'activityPage', meta: { describe: '活动页面' }, component: () => import('../page/activityPage/index.vue') },
        { path: '/signOut', name: 'signOut', meta: { describe: '退出登录' }, component: () => import('../page/login/signOut.vue') }
      ]
    },
    { // 匹配不到跳转主页
      path: '*',
      name: 'notfound',
      redirect: '/'
    }
  ],
  scrollBehavior (to) {
    if (!(to.fullPath.indexOf('supportCenter') > -1)) {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history'
})
router.beforeEach(async (to, from, next) => {
  let route = to
  let replace = false

  // 将不是'/'结尾的链接改成以'/'结尾
  if (!determineIfTheLinkEndWithSlash(to)) {
    route = modifyTheLinkEndToWithSlash(to)
  }

  // 如果链接参数有带token，则用token登录
  if (to.query.token) {
    const token = to.query.token
    // if (to.query.isSSOToken === 'true') {
    //   // 用SSOToken去换M端token
    //   token = await action.GET_M_TOKEN(to.query.token)
    //   route = Object.assign({}, route)
    //   delete route.query.isSSOToken
    // }
    await action.SAVE_USERINFO({ commit: store.commit }, token)
    route = Object.assign({}, route)
    delete route.query.token
    replace = true
  }
  if (route !== to) {
    route.fullPath = route.path
    for (const key in route.query) {
      if (route.fullPath.indexOf('?') >= 0) {
        route.fullPath += `&${key}=${route.query[key]}`
      } else {
        route.fullPath += `?${key}=${route.query[key]}`
      }
    }
    if (route.hash) {
      route.fullPath += route.hash
    }
    next({
      replace,
      path: route.fullPath
    })
  } else {
    next()
  }
})

export default router

// 判断链接是否以'/'结尾
function determineIfTheLinkEndWithSlash (route) {
  // 没有 query 和 hash 的情况下
  if (route.fullPath === route.path) {
    return /\/$/.test(route.path)
  }
  // 有 query 的情况下
  if (route.fullPath.indexOf('?') >= 0) {
    return route.fullPath.indexOf('/?') >= 0
  }
  // 有 hash 的情况下
  if (route.fullPath.indexOf('#') >= 0) {
    return route.fullPath.indexOf('/#') >= 0
  }
}

// 修改链接以'/'结尾
function modifyTheLinkEndToWithSlash (route) {
  route = Object.assign({}, route)
  // 没有 query 和 hash 的情况下
  if (route.fullPath === route.path) {
    route.path += '/'
    route.fullPath += '/'
    return route
  }
  // 有 query 的情况下
  if (route.fullPath.indexOf('?') >= 0) {
    route.path += '/'
    route.fullPath = route.fullPath.replace('?', '/?')
    return route
  }
  // 有 hash 的情况下
  if (route.fullPath.indexOf('#') >= 0) {
    route.path += '/'
    route.fullPath = route.fullPath.replace('#', '/#')
    return route
  }
}
