// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import store from './store/store'
import router from './router/router'
import './module/screen'
import './module/group'
import { Message } from 'element-ui'
import VueLazyload from 'vue-lazyload'
import $href from './module/href'
import { trackVue } from '@alltuu/track'
import { thirdBridgeVue } from '@alltuu/thirdBridge'
import { notification } from '@alltuu/alltuu-ui'
import { debug, trackConfig } from './config/env'
import { wechatConfigApi } from './service/getData'
Vue.prototype.$href = $href

/* ============添加第三方（微信、钉钉）插件============== */
Vue.use(thirdBridgeVue, {
  debug: debug || location.href.indexOf('debug=') >= 0,
  getSignature: async url => {
    const res = await wechatConfigApi(encodeURIComponent(url), process.env.VUE_APP_WECHAT_APP_ID)
    if (res.errorCode === 0) {
      return res.data.config
    }
    throw new Error('wechat config 接口请求错误')
  }
})

Vue.use(trackVue, {
  env: process.env.VUE_APP_ENV,
  encryption: true,
  config: {
    ...trackConfig
  },
  globalData: {
    _from_: process.env.VUE_APP_SIGNATURE_FROM
  }
})

Vue.use(VueLazyload)
Vue.prototype.$notify = notification
Vue.config.productionTip = false

Vue.prototype.$message = Message

// 路由判断是否需要登陆
router.beforeEach((to, from, next) => {
  next()
})
var _hmt = window._hmt || []

router.afterEach(hook => {
  _hmt.push(['_trackPageview', hook.fullPath])
})

// document.addEventListener('DOMContentLoaded', function () {
//   new Vue({
//     el: '#app',
//     store,
//     router,
//     template: '<App/>',
//     components: {
//       App
//     }
//   }).$mount('#app')
// })
window.alltuuApp = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
