/* 公共配置汇总暴露接口
 *
 *                            _ooOoo_
 *                           o8888888o
 *                           88" . "88
 *                           (| -_- |)
 *                           O\  =  /O
 *                        ____/`---'\____
 *                      .'  \\|     |//  `.
 *                     /  \\|||  :  |||//  \
 *                    /  _||||| -:- |||||-  \
 *                    |   | \\\  -  /// |   |
 *                    | \_|  ''\---/''  |   |
 *                    \  .-\__  `-`  ___/-. /
 *                  ___`. .'  /--.--\  `. . __
 *               ."" '<  `.___\_<|>_/___.'  >'"".
 *              | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *              \  \ `-.   \_ __\ /__ _/   .-` /  /
 *         ======`-.____`-.___\_____/___.-`____.-'======
 *                            `=---='
 *        ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 *                      Buddha Bless, No Bug !
 *
 */
const config = require(`./${process.env.VUE_APP_ENV_FILE}`)
const { version } = require('../../package.json')

// 主域名
const domain = process.env.VUE_APP_DOMAIN

// 项目访问域名
const accessPath = `https://x.${process.env.VUE_APP_ENTER_URL}.com`
module.exports = {
  // 应用版本
  version,

  // 项目访问路径
  accessPath,

  // 项目主域名
  domain,
  nowDomain: domain,

  // 旧服务器接口路径
  baseUrl: `https://om.${domain}.com`,
  apiUrl: `https://om.${domain}.com`,
  // 新设置服务器接口路径
  newApiUrl: `https://anm.${domain}.com`,
  // 萌芽服务器接口
  auxApiUrl: `https://aux.${domain}.com`,

  mRestApi: `https://a.${domain}.com`,

  productionSourceMap: false, // 是否生成 Source Map 文件
  debug: false, // 是否开启调试

  // 函数计算接口
  fcApi: `https://cal.${domain}.com`,
  ...config
}
