import md5 from '../module/md5'
import { getCookie } from '../module/cookie'

// 选择签名类型
const signature = (options, version = 'v1', from, newtoken) => {
  if (version === 'v1') {
    return v1(options)
  }
  if (version === 'v3') {
    return v3(options, from, newtoken)
  }
}

// 获取token
const getToken = () => {
  return (getCookie('alltuuuim') && JSON.parse(getCookie('alltuuuim')).token) || 'null'
}

// rest、rest/v1、rest/v2接口签名算法
const v1 = options => {
  let signatureStr = ''
  let signatureMd5 = ''
  const token = getToken()
  const timestamp = new Date().getTime()
  const param = options

  const objKey = Object.keys(param).sort()
  for (let i = 0, len = objKey.length; i < len; i++) { signatureStr += '/' + param[objKey[i]] }

  signatureStr += `/${timestamp}/${token}`
  // console.log(signatureMd5)
  signatureMd5 = md5(signatureStr)
  return {
    signature: `${timestamp}/${token}/${signatureMd5}`,
    obj: {
      timestamp: timestamp,
      token: token,
      signatrue: signatureMd5
    }
  }
}

// rest/v3接口签名算法

const v3 = (options, from, newtoken) => {
  let signatureStr = ''
  let signatureMd5 = ''
  const param = {
    from: process.env.VUE_APP_SIGNATURE_FROM,
    version: '0',
    token: getToken(),
    timestamp: new Date().getTime()
  }
  if (newtoken) param.token = newtoken
  Object.assign(param, options)
  const objKey = Object.keys(param).sort()
  for (let i = 0, len = objKey.length; i < len; i++) { signatureStr += '/' + param[objKey[i]] }
  signatureMd5 = md5(signatureStr)
  if (!newtoken) {
    return {
      signature: `v${param.from}-${param.timestamp}-${param.token}-${param.version}-${signatureMd5}`,
      obj: param
    }
  } else {
    return {
      signature: `v${param.from}-${param.timestamp}-${newtoken}-${param.version}-${signatureMd5}`,
      obj: param
    }
  }
}

// 请求路径拼接
export default (url, options = {}, sign, from = 0, newtoken) => {
  let signatureType = 'v1'
  if (url.indexOf('rest/v3') >= 0 || url.indexOf('http://biu.') >= 0 || url.indexOf('https://cal.') >= 0) {
    signatureType = 'v3'
  }
  sign = sign || options
  const _sign = signature(sign, signatureType, from, newtoken)

  for (const i in options) {
    url += '/' + i + options[i]
  }

  return url + '/' + _sign.signature
}
