/*
 * 用户对象
 */
import { toNowDay } from '@/module/date'
export default class User {
  constructor (data) {
    const pgObj = data.obj || data.cookiesDTO || data // 账号登录是obj，第三方登录是cookiesDTO

    this.userId = data.id
    this.id = data.userId || pgObj.userId || data.usrId || data.id // 用户id
    this.group = data.grpId // 用户组
    this.nick = data.nick || data.name // 用户组
    this.token = data.token // 身份令牌
    this.portrait = pgObj.url || pgObj.portrait || 'https://spu.alltuu.com/face/PG0/latest.jpg' // 用户头像
    this.isPg = !!data.proId
    this.phoneNum = data.phone
    this.version = '1.0.0' // 用户格式版本
    this.isWxlogin = data.isWxlogin // 微信登录
    this.isWblogin = data.isWblogin // 微博登录
    this.isDDLogin = data.isDDlogin // dingding登录
    this.hasPhone = !!data.hasPhone
    this.appId = pgObj.appId ? pgObj.appId : ''
    // 是否是黄金会员
    this.goldVip = !!data.goldType
    // 是否是钻石会员
    this.diamondVip = !!data.diamondType
    // 是否是会员
    this.isVip = this.goldVip || this.diamondVip
    // 会员结束时间
    this.vipEndTime = this.isVip ? (this.goldVip && data.thmExpireTime) || (this.diamondVip && data.adExpireTime) : ''
    // 会员剩余天数
    this.remainTime = this.isVip ? toNowDay(+new Date(), this.vipEndTime) : 0
  }
}
