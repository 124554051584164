
export default {
  getUserInfo (state) {
    let userInfo = state.userInfo
    if (!userInfo.token) {
      userInfo = window.getCookie('alltuuuim') || '{}'
      state.userInfo = userInfo = JSON.parse(userInfo)
    }
    return userInfo
  }
}
