import { newGetInfoForToken } from '../service/getData'
import User from '../object/User'
import Photographer from '../object/Photographer'
export default {
  // 记录用户信息
  async SAVE_USERINFO ({ commit }, token) {
    const res = await newGetInfoForToken(token)
    if (res.e === 0) {
      const userInfo = res.d.obj.proId ? new Photographer(res.d.obj) : new User(res.d.obj)
      commit('SAVE_USERINFO', userInfo)
    }
  }
}
