export default {
  // 记录用户信息
  SAVE_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
    userInfo.loginTime = +new Date()
    window.setCookie('alltuuuim', userInfo, 30)
  },
  // 删除用户信息
  DELETE_USERINFO: (state) => {
    state.userInfo = {}
    window.removeCookie('alltuuuim')
  },
  toChangeTab: (state) => {
    state.changeTab++
  }
}
