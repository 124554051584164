import fetch from '../module/fetch'
import signature from '../module/signature'
import { apiUrl, newApiUrl, fcApi, auxApiUrl } from '../config/env'

/********************************************************
 *                                                      *
 *                        页面数据                       *
 *                                                      *
 ********************************************************/
// 获取大活动状态
export const getActivityStatus = () => fetch(signature(apiUrl + '/rest/v3/index/big/activity/status', {}, {}))

// 获取是否有标星权限
export const couldStar = (albumIdN) => fetch(signature(apiUrl + '/rest/v3/user/check/auth', {}, { albumIdN }), { albumIdN }, 'post')

// 图片打星分数
export const starCount = (photoId, starLevel) => fetch(signature(apiUrl + '/rest/v3/retouch/star/photo', {}, { photoId, starLevel }), { photoId, starLevel }, 'post')

// 获取大客户简介
export const getCustomerInfo = (sub) => fetch(signature(apiUrl + `/rest/v3/user/big/custom/sub${sub}`, {}, { sub }))

// PC用户登出接口！！测试环境没用
export const pcloginOut = (jsessionId) => fetch(signature(apiUrl + `/rest/v3/logout/jsessionId${jsessionId}`, {}, { jsessionId }))

// PC 登录验证
export const pcLoginVertify = (sub) => fetch(signature(apiUrl + `/rest/v3/validate/login/sub${sub}`, {}, { sub }))

// pc 友情链接获取

export const getFreindChain = (origin) => fetch(signature(apiUrl + `/rest/v3/pc/footer/info/origin${origin}`, {}, { origin }))

// 获取摄像师的状态
export const getPhotographerStatus = () => fetch(signature(apiUrl + '/rest/v3/camera/state/info', {}, {}))

// m ossApi
export const ossApi = a => fetch(a)

export const getAlbumDetailInfo = (albumIdN, pwd = '') => fetch(signature(apiUrl + `/rest/v3/album/pc/direct/album/info/albumIdN${albumIdN}/pwd${pwd}`, {}, { albumIdN, pwd }))

// pc直播頁面 获取图片
export const getAlbumPhotoList = (albumIdN, cnt, id, type, sort, sepId, pwd) => fetch(signature(apiUrl + `/rest/v3/album/pc/direct/photo/info/albumIdN${albumIdN}/sort${sort}/cnt${cnt}/id${id}/type${type}/sepId${sepId}/pwd${pwd}`, {}, { albumIdN, cnt, id, type, sort, sepId, pwd }))

// 云相册校验大屏互动链接是否正确
export const verifyAiphotoCode = (code) => fetch(signature(auxApiUrl + '/rest/v3/snaphoto/album/query/checkAlbumPlayCode', {}, { code }), { code }, 'post')

// 微信小程序生成URL scheme type 云摄影传1，萌芽相册不传或传0
export const createScheme = (path, query, type) => fetch(signature(newApiUrl + '/rest/v3/thirdPart/generateUrlScheme', {}, { path, query }), { path, query, type }, 'post')

// 新token登录
export const newGetInfoForToken = (token) => fetch(signature(newApiUrl + '/rest/v3/user/userInfoByToken', {}, { token }), { token }, 'post')

// 查询用户是否参与过邀请上传送会员活动
export const selectUserIsDrawInviteVip = () => fetch(signature(auxApiUrl + '/rest/v3/snaphoto/user/query/selectUserIsDrawInviteVip', {}, {}), {}, 'post')

// 获取微信分享配置
export const wechatConfigApi = (url, appId = '') => fetch(signature(fcApi + '/wechat/config', {}, { url, appId }), { url: encodeURIComponent(url), appId }, 'post')
