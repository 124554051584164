// 重写alert
import alert from './alert'

// tip提示框
import tipBox from './tipBox'

// Cookie操作
import { setCookie, getCookie, removeCookie, clearCookie } from './cookie'
window.alert = alert
window.tipBox = tipBox
window.setCookie = setCookie
window.getCookie = getCookie
window.removeCookie = removeCookie
window.clearCookie = clearCookie
