// domain 为本站域名 例如pc端 为 alltuu
import { domain } from '../config/env'
const outlink = 'https://out.alltuu.cn/wild/index.html'
function $href (url) {
  url = encodeURIComponent(url)
  if (process.env.NODE_ENV === 'development') {
    if (url.indexOf('localhost') > -1) {
      location.href = decodeURIComponent(url)
    } else {
      location.href = `${outlink}?go=${url}`
    }
  } else if (url.indexOf(domain + '.com') > -1) {
    location.href = decodeURIComponent(url)
  } else {
    location.href = `${outlink}?go=${url}`
  }
}
export default $href
