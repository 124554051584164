/*
 * 错误码处理
 */
const errorCode = {
  // global result
  1: '系统错误，请刷新或返回首页',
  2: '请登录后操作',
  3: '请申请摄影师后操作',
  4: '签名错误',
  5: '登录超时', // token有误
  6: '邮件发送失败',
  7: '验证码错误',
  8: '参数错误', // 参数不再限制范围
  9: '9:未知错误', // 未知错误
  10: '验证码为空',
  11: '您手机的时间不正确', // 时间戳格式错误
  12: '手机格式错误',

  // DB
  30: '服务器错误',
  31: '服务器错误',
  32: '服务器错误',
  33: '服务器错误', // DB插入数据有误
  99: '服务器错误',

  // OSS
  100: '服务器错误',
  101: '服务器错误',
  102: '服务器错误',
  103: '验证码错误',
  104: '验证码已过期',
  105: '验证码为空',

  130: '编码错误',
  131: '131:未知错误', // 未知错误

  // Login
  1000: '请输入用户名',
  1001: '请输入密码',
  1002: '用户名不存在',
  1003: '账号或密码错误',

  // Reg
  1004: '请输入用户名',
  1005: '请输入密码',
  1008: '登录信息已过期，请重新登录',
  1100: '用户已存在',
  1152: '密码不相等',

  // Pro
  1200: '您还不是喔图摄影师，快去注册吧',
  1201: '该手机号码已注册',
  1202: '头像上传错误，请重新上传',
  1203: '域名已存在，请选择其他域名',
  1204: '编辑无效，请重新操作',
  1205: '验证码失效',
  // 1206:'不是合作摄影师', //使用弹出层提示
  1207: '您已经申请该活动拍摄',

  // Work
  1300: '作品集不存在',
  1301: '无编辑权限',
  1302: '作品数量超过作品集上限',
  1303: '作品集发布失败，请重新上传',

  // content
  1350: '照片不存在',

  // Product（服务）
  1400: '服务不存在',
  1401: '发布失败，请重新发布',
  1402: '样片超过上限',
  1403: '无编辑权限',
  1404: '预约失败，请重新预约',
  1405: '您没有收藏该服务',
  1406: '您已经收藏该服务',

  // example
  1450: '照片不存在',

  // Album
  1500: '图库信息异常',
  1501: '无发送权限',
  1502: '图库不存在',
  1503: '1503:未知错误', // 未知错误
  1504: '相册不存在',
  1505: '图库已发送成功',
  1506: '对方账号不存在',
  1507: '无编辑权限',
  1508: '照片不存在',
  1509: '1509:未知错误', // 未知错误
  1510: '1510:未知错误', // 未知错误
  1511: '旋转失败，请重新操作',

  // like
  1600: '操作失败',
  1601: '已点过赞',

  // activity(打开加密的活动相册时)
  1700: '密码错误',
  1701: '没有输入密码',
  1702: '该评论不存在，请刷新页面',
  1703: '您没有删除该评论的权限',
  1704: '该活动不存在',
  1705: '该活动内不存在该分类',
  1706: '该征集不存在',

  1800: '未关注该摄影师',
  1801: '已关注该摄影师',

  2000: '订单生成错误',
  2001: '订单异常',

  2003: '微信基础授权accesstoken失效',
  2004: '微信卡券已核销',
  2005: '微信卡券已过期',
  2006: '微信卡券转赠中',
  2007: '微信卡券转赠超时',
  2008: '微信卡券已删除',
  2009: '微信卡券已失效',
  2010: '微信卡券code解码失败',
  2011: '微信授权错误',
  2012: '卡券不为当前微信用户所有',
  2013: '该卡券不适用当前订单',

  2401: 'photoIds中没有照片唯一标识',

  2601: '该征集已结束',
  2605: '该征集不存在',
  2606: '该征集已经被用户取消',
  2607: '未获取到砍价信息',
  2608: '摄影师已经取消报名',
  2609: '您已经选择过摄影师',
  2630: '该征集正在审核中',

  2751: '未获取到授权信息',

  2801: '钱包余额不够',
  3201: '您已开通过闪传',
  3301: '微信支付生成预支付订单失败',
  3302: '支付出错',
  3303: '获取授权token失败',
  3304: '微信卡券已核销',
  3305: '微信卡券已过期',
  3306: '微信卡券转赠中',
  3307: '微信卡券转赠超时',
  3308: '微信卡券已删除',
  3309: '微信卡券已失效',
  3310: '微信卡券code错误',
  3311: '微信用户授权失败',
  3312: '卡券不是当前用户拥有',
  3313: '当前卡券不能核销此订单',
  3314: '卡券核销失败',
  3315: '卡券信息为空',
  3316: '卡券占用异常',

  5001: '您已成功报名，请勿重复提交',

  7001: '当前账户已是摄影师，不需要绑定',
  7002: '当前账号不是三方授权登陆账户',
  7003: '该账号已经绑定过qq',
  7004: '该账号已经绑定过微信',
  7005: '该账号已经绑定过微博',

  40056: '卡券码不合法',
  40001: '获取卡券信息失败，请稍后再试'
}

export default (code) => errorCode[code]
