/*
 * 操作cookie或与cookie相关操作
 *
 * 参数:
 * name:cookie 名
 * value:cookie 值
 * Days:cookie 生命周期（天）
 */
import { verify } from '@alltuu/verify'
import { cookieDomain } from '../config/env'
const sameSite = verify('dingtalk') ? 'None' : 'Strict'

// 写入cookie
export function setCookie (name, value, Days = 1) {
  var exp = new Date()
  exp.setTime(exp.getTime() + Days * 86400000)// 24 * 60 * 60 * 1000
  if (typeof value === 'object') value = JSON.stringify(value)
  document.cookie = name + '=' + escape(value) + ';expires=' + exp.toGMTString() + `;SameSite=${sameSite};path=/;domain=` + getCookieDomain()
}
/// 删除cookie
export function removeCookie (name) {
  const cval = getCookie(name)
  if (cval != null) setCookie(name, cval, 0)
}
// 读取cookie
export function getCookie (name) {
  var arr = document.cookie.match(new RegExp('(^| )' + name + '=([^;]*)(;|$)'))
  return arr == null ? undefined : unescape(arr[2])
}
// 清空cookie
export function clearCookie () {
  var arr = document.cookie.split('; ')
  for (let i = 0, len = arr.length; i < len; i++) {
    removeCookie(arr[i].split('=')[0])
  }
}

function getCookieDomain () {
  if (verify('ipAddress', location.hostname)) {
    return location.hostname
  } else {
    return cookieDomain
  }
}
