/** 测试和开发环境公共配置
 */

module.exports = {
  imgBaseUrl: 'https://sr.alltuu.com/vue-pc/images',
  wechatAppId: 'wx776c524e5ccccd55',
  cookieDomain: `.xxalbum.com`,
  // 日志服务配置项
  trackConfig: {
    host: 'cn-hangzhou.log.aliyuncs.com',
    project: 'alltuu-frontend-log-tracking',
    logstore: 'alltuu-frontend-log-tracking-encryption'
  },
  productionSourceMap: false,
  debug: false
}
