/**
 * 格式化摄影师对象
 * @param {string}  data   包含摄影师数据的不规则数据
 */
import User from './User'

export default class Photographer extends User {
  constructor (data) {
    super(data)

    this.proId = data.proId || data.id
    this.sub = data.sub
    this.sex = !!data.sex // 0：女 | 1：男
    this.type = !!data.type // 0：摄影师 | 1：企业
    this.dsc = data.dsc
    this.addrCode = data.addrCode || 330100
    this.host = data.sub && 'http://' + data.sub + '.alltuu.com'
    this.cover = data.bgv ? `http://pub.alltuu.com/bg/PG${this.proId || this.id}/${data.bgv}.jpg` : 'http://pub.alltuu.com/bg/PG0/0.jpg'
    this.isWxlogin = data.isWxlogin
    this.token = data.token
  }
}
