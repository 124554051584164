// import verify from '../module/verify'
import { clearCookie } from '@/module/cookie'
import errorCode from './errorCode'
import printError from './printError'
import fetch from 'node-fetch'
import { nowDomain } from '../config/env'

// 不提示的错误码
const NOT_PROMPT_ERROR_CODE = [0, 1206, 1350, 1519, 1701, 2705, 2805, 3605]

export default (url, data, type, method) => {
  const _request = request(url, data, type, method)

  _request.then((res) => {
    const getErrorCode = res.errorCode || res.e
    // 需要重新登录
    if (res && NOT_PROMPT_ERROR_CODE.indexOf(getErrorCode) < 0) {
      if ([2, 5, 1008, 200001].includes(getErrorCode)) {
        getErrorCode === 2 && window.tipBox('请登录后操作')
        getErrorCode === 5 && window.tipBox('登录超时，请重新登录')
        getErrorCode === 1008 && window.tipBox('登录信息已过期，请重新登录')
        getErrorCode === 200001 && window.tipBox('登录信息已过期，请重新登录')
        clearCookie()
        location.href = `https://v.${nowDomain}.com/login`
      } else if (getErrorCode === 1222) {
        window.tipBox('摄影师域名不存在')
        location.href = `https://www.${nowDomain}.com/`
      } else {
        if (getErrorCode !== undefined) {
          window.tipBox(res.msg || res.message || res.m || errorCode[getErrorCode] || '未知错误，错误码:' + getErrorCode)
        }
      }
    }
  }).catch(() => {
    console.log('请求错误，请联系客服<br/>电话：0571-87711285')
    // window.tipBox('请求错误，请联系客服<br/>电话：0571-87711285');
  })
  return _request
}

async function request (url = '', data = {}, type = 'GET', method = 'fetch') {
  // console.log(url,data,type,method)
  const timestamps = new Date().getTime()
  type = type.toUpperCase()

  // 数据拼接字符串
  let dataStr = ''

  Object.keys(data).forEach(key => {
    // 相册改版 内容
    if (url.indexOf('nm.guituu') >= 0 || url.indexOf('nm.alltuu') >= 0 || url.indexOf('ct.guituu') >= 0 || url.indexOf('ct.alltuu') >= 0 || url.indexOf('anm.piufoto') >= 0) {
      dataStr += key + '=' + encodeURIComponent(data[key]) + '&'
    } else {
      dataStr += key + '=' + data[key] + '&'
    }
  })

  dataStr = dataStr ? dataStr.substr(0, dataStr.lastIndexOf('&')) : ''

  if (type === 'GET' && dataStr !== '') {
    url = url + '?' + dataStr
  }

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded'
  }

  const requestConfig = {
    credentials: 'true',
    method: type,
    headers: headers,
    mode: 'CORS'
  }

  if (type === 'POST') {
    requestConfig.body = dataStr
  }
  const response = await fetch(url, requestConfig)
  const responseJson = await response.json()

  responseJson.errorCode ? printError('请求成功,数据错误', { type, url, data, return: responseJson }) : printError('请求成功', { type, url, data, return: responseJson }, 'success')
  responseJson.request = {
    timestamps
  }
  return responseJson
}
